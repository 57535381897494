import DetailsDialogElement from '@github/details-dialog-element'
import {SelectedItemCountElement} from './selected-item-count'
import {SelectedItemListElement} from './selected-item-list'
import {hasDirtyFields} from '../has-interactions'
import {on} from 'delegated-events'

const selected = 'SELECTED'

on(
  'details-menu-selected',
  '.js-runner-group-component-visibility-menu',
  function (event) {
    const target = event.detail.relatedTarget
    const runnerGroupForm = target.closest('.js-runner-group-form') as HTMLElement
    const value = target.getAttribute('value')!
    runnerGroupForm.querySelector<HTMLElement>('.js-runner-group-targets-selection')!.hidden = value !== selected

    // hide the count of items if visibility is not set to "Selected" (i.e. Visbility is set to "All")
    runnerGroupForm.querySelector<HTMLElement>('.js-runner-group-targets-count')!.hidden = value !== selected
  },
  {capture: true}
)

document.addEventListener('details-dialog-close', function (event) {
  const selectedItems = (event.target as Element).closest<SelectedItemListElement>('selected-item-list')!
  if (!selectedItems) {
    return
  }
  for (const item of selectedItems.items) {
    // reset checks to last saved state
    item.checked = item.defaultChecked
  }
  selectedItems.updateCount()
})

on('click', '.js-btn-select-items', function (event) {
  const detailsDialog = (event.target as Element).closest<DetailsDialogElement>('details-dialog')!
  // save the changes to selected items
  const selectedItems = (event.target as Element).closest<SelectedItemListElement>('selected-item-list')!
  if (hasDirtyFields(detailsDialog)) {
    for (const item of selectedItems.items) {
      item.defaultChecked = item.checked
    }
  }

  // Close the details dialog
  detailsDialog.toggle(false)

  // update the selected items count
  const selectedItemCount = selectedItems.querySelector<SelectedItemCountElement>('selected-item-count')!
  selectedItemCount.updateCount(Number(selectedItems.selectedCount.textContent))
})
